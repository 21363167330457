import React,{useEffect, useState,useContext} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate,faChalkboardUser,faUserShield,faSearch, faPeopleRoof,faSpinner, faArrowRight,faChevronLeft,
  faChevronRight} 
from "@fortawesome/free-solid-svg-icons";
import { Link,useNavigate } from "react-router-dom";
import styles from './Home.module.css'
import { GetInfl_Info, searchInfluencers } from '../../services/auth-service/InflAccountService';
import { GetClient_Info } from '../../services/auth-service/clientAccountService';
import VideoPlayer from '../../components/shared/videoplayer/Videoplayer';
import { UserContext } from '../../App';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from 'jquery';
import HomeCard from './homecard/HomeCard';
import BookInfluencer from '../clientAbout/bookInfluencer/BookInfluencer';


window.$ = window.jQuery = $;
const Home = () => {
    const navigate = useNavigate();
    const { state } = useContext(UserContext);

    const [loading, setLoading] = useState(true);
    const [popup, setPopup] = useState(null);
    const [influencers,setInfluencers]=useState([])
    const [showOverlayProfile, setShowOverlayProfile] = useState(false);
    const [selectedInfluencer, setSelectedInfluencer] = useState(null);

  const cardsData = [
    { firstName: "John Doe", category: "Fitness", sessionFees: { half: 50, full: 90 } },
    { firstName: "Jane Smith", category: "Fashion", sessionFees: { half: 60, full: 100 } },
    { firstName: "Alice Johnson", category: "Cooking", sessionFees: { half: 70, full: 120 } },
    { firstName: "Bob Brown", category: "Photography", sessionFees: { half: 80, full: 150 } },
    { firstName: "Chris Evans", category: "Tech", sessionFees: { half: 90, full: 180 } },
    { firstName: "See More", category: "Tech", sessionFees: { half: "-", full: "-" } },
  ];

  const handleCardClick = (influencerData) => {
    setSelectedInfluencer(influencerData);
    setShowOverlayProfile(true);
  };
  const handleCloseOverlay = () => {
    setSelectedInfluencer(null);
    setShowOverlayProfile(false);
  };

  const options = {
    loop: true,
    margin: 10,
    nav: false,
    dots: false,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      900: { items: 3 },
      1150: { items: 4 }
    },
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
  };
  const handleOwlCarouselButton = (direction) => {
    $(".owl-carousel .owl-" + direction + "").click();
  };

  // useEffect(() => {
  //   // Initialize Owl Carousel on component mount
  //   const owl = document.querySelector('.owl-carousel');
  //   if (owl) {
  //     $(owl).owlCarousel(options);
  //   }
  // }, []);



    useEffect(() => {
      const checkLogin = async () => {
        try {
          if(state){
            const clientResponse = await GetClient_Info();
          if (clientResponse.status === 200)
            {
              navigate('/clienthome');
            } else {
            const inflResponse = await GetInfl_Info();
            if (inflResponse.status === 200) {             
              navigate('/influencerhome');
            } 
           }
          }else{
            try {
              const pageSize =15;
              const query = "";
              const page = 1;
              const response = await searchInfluencers(query,page,pageSize);
              setInfluencers(response.influencers);
              // setTotalPages(response.totalPages);
              } catch (error) {
                console.error('Error fetching influencers:', error);
              }
          }
        } catch (error) {
          console.error("Error occurred during login check:", error);
          // Handle other errors (e.g., network error, server error)
        } finally {
          setLoading(false);
        }
      };
    checkLogin();
    }, [])

  return (
    <>
    {loading ? (
      // Display spinner while waiting for the response
      <div className="spinner-container">
      <FontAwesomeIcon
                      className="spinner"
                      icon={faSpinner}
                    />
                    </div>
    ) : (
    <div>
    <section id="hero" className="inflanar-hero inflanar-bg-cover p-relative inflanar-ohidden">
<div className="container">
<div className="row">
<div className="col-12">
<div className={`${styles.shadedbox} inflanar-hero__inside `}>
<div className="inflanar-hero__inner">

<div className="inflanar-hero__content aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
<span className="inflanar-hero__sub inflanar-regular-font ml-1">Knowledge Sharing Platform For Influencers & Professionals</span>
<h1 className="inflanar-hero__title"><span>Convenient</span> Conversation, Endless Impact</h1>
</div>


<div className="inflanar-sclient aos-init aos-animate" data-aos="fade-up" data-aos-delay="600">
<ul className="inflanar-sclient__list list-none">
<li><span><img className={`${styles.belowSearchimg}`} src="img/client1.png" alt='hello'/></span></li>
</ul>
<h4 className="inflanar-sclient__title"><b className="in-counter" placeholder='52435 +'></b><span placeholder='Satisfied Clients'></span></h4>
</div>
</div>
<div className="inflanar-hero__img aos-init aos-animate" data-aos="fade-left" data-aos-delay="700">
<img src="img/Social-56.png" alt='hello'/>




</div>
</div>
</div>
</div>
</div>

</section>

<section className="pd-top-120 ">
<div className="container">
<div className="row">
<div className="col-12 mb-4">
<div className={styles.carousel_container}>
<div className="inflanar-section__head inflanar-section__center text-center mg-btm-20">
<h2 className="inflanar-section__title aos-init aos-animate" data-aos="fade-in" data-aos-delay="400">Book a Session</h2>
</div>
<div className="row">
          <div className="col-12 text-right">
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="carousel-buttons mr-4 cursor-pointer"
              onClick={() => {
                handleOwlCarouselButton("prev");
              }}
            />
            <FontAwesomeIcon
              icon={faChevronRight}
              className="carousel-buttons cursor-pointer"
              onClick={() => {
                handleOwlCarouselButton("next");
              }}
            />
          </div>
        </div>

<OwlCarousel className="owl-carousel" {...options}>
  {influencers.map((influencer, index) => (
    <div className='cursor-pointer' key={index} onClick={() => handleCardClick(influencer)}>
        <HomeCard
        firstName={influencer.firstName}
        lastName={influencer.lastName}
        category={influencer.category}
        insta={influencer.Instagram}
        verifyInstagram={influencer.verifyInstagram}
        fb={influencer.Facebook}
        verifyFacebook={influencer.verifyFacebook}
        linkdin={influencer.Youtube}
        verifyYoutube={influencer.verifyYoutube}
        twitter={influencer.Twitter}
        verifyTwitter={influencer.verifyTwitter}
      />
    </div>
  ))}
</OwlCarousel>
<Link className='float-right mt-2' to='/homeall'><span className="inflanar-psidebar__title ">Show all </span>
<FontAwesomeIcon
icon={faArrowRight}
className="carousel-buttons cursor-pointer"
/></Link>
</div>
</div>
<div className="col-12">
<div className="inflanar-section__head inflanar-section__center text-center mg-btm-40">
<h2 className="inflanar-section__title aos-init aos-animate" data-aos="fade-in" data-aos-delay="400">Discover In Korero for 1:1 & Group Session</h2>
</div>
  <VideoPlayer />
</div>
</div>
</div>
</section>

<section className="pd-top-120">
<div className="container">
<div className="row">
<div className="col-12">
<div className="inflanar-features-list inflanar-section-shape14 inflanar-bg-cover">
<div className="row">
<div className="col-12">

<div className="inflanar-section__head inflanar-section__center mg-btm-20">

<h2 className="inflanar-section__title aos-init aos-animate" data-aos="fade-in" data-aos-delay="400">Our Salient Features</h2>
</div>
</div>
</div>
<div className="row inflanar-features-gap">
<div className="col-lg-3 col-md-6 col-6 mg-top-30 aos-init aos-animate" data-aos="fade-in" data-aos-delay="400">

<div className="inflanar-features-list__single">
<div className="inflanar-features-list__head">
<div className="inflanar-features-list__first">
<div className="inflanar-features-list__icon ">
<FontAwesomeIcon
                      style={{fontSize: "40px",color: "#fff"}}
                      data-toggle="collapse"
                      icon={faPeopleRoof}
                    />
</div>
</div>
</div>
<div className="inflanar-features-list__body">
<h4 className="inflanar-features-list__title">Innovative collaboration</h4>
<p className="inflanar-features-list__text">Innovative collaboration with influencers introduces an exciting dimension to user engagement, leveraging the unique perspectives, creativity, and reach of influencers to create compelling experiences. We partner 
with influencers who resonate with the target audience to spark meaningful conversations. </p>
</div>
</div>

</div>
<div className="col-lg-3 col-md-6 col-6 mg-top-30 aos-init aos-animate" data-aos="fade-in" data-aos-delay="600">

<div className="inflanar-features-list__single">
<div className="inflanar-features-list__head">
<div className="inflanar-features-list__first">
<div className="inflanar-features-list__icon inflanar-scolor-bg">
<FontAwesomeIcon
                      style={{fontSize: "40px",color: "#fff"}}
                      data-toggle="collapse"
                      icon={faCertificate}
                    />
</div>
</div>
</div>
<div className="inflanar-features-list__body">
<h4 className="inflanar-features-list__title">Verified Influencers</h4>
<p className="inflanar-features-list__text">Influencers play a pivotal role in today's digital landscape, serving as trusted voices who wield significant influence over their audiences. Leveraging their expertise, authenticity, and reach, these influencers have the power to 
shape perceptions, drive trends, and catalyze action within their respective niches.</p>
</div>
</div>

</div>
<div className="col-lg-3 col-md-6 col-6 mg-top-30 aos-init aos-animate" data-aos="fade-in" data-aos-delay="800">

<div className="inflanar-features-list__single">
<div className="inflanar-features-list__head">
<div className="inflanar-features-list__first">
<div className="inflanar-features-list__icon inflanar-tcolor-bg">
<FontAwesomeIcon
                      style={{fontSize: "40px",color: "#fff"}}
                      data-toggle="collapse"
                      icon={faChalkboardUser}
                    />
</div>
</div>
</div>
<div className="inflanar-features-list__body">
<h4 className="inflanar-features-list__title">Positive Engagement</h4>
<p className="inflanar-features-list__text">Positive engagement epitomizes the essence of meaningful interactions within communities, digital platforms, and social networks. It embodies genuine connections, mutual respect, and constructive dialogue that foster empathy, 
understanding and support among individuals, nurturing collective well-being. </p>
</div>
</div>

</div>
<div className="col-lg-3 col-md-6 col-6 mg-top-30 aos-init aos-animate" data-aos="fade-in" data-aos-delay="1000">

<div className="inflanar-features-list__single">
<div className="inflanar-features-list__head">
<div className="inflanar-features-list__first">
<div className="inflanar-features-list__icon inflanar-ylcolor-bg">
<FontAwesomeIcon
                      style={{fontSize: "40px",color: "#fff"}}
                      data-toggle="collapse"
                      icon={faUserShield}
                    />
</div>
</div>
</div>
<div className="inflanar-features-list__body">
<h4 className="inflanar-features-list__title">Happy Users</h4>
<p className="inflanar-features-list__text">Happy users represent individuals who find value and derive joy and satisfaction through In Korero. Their happiness signifies a seamless alignment between user needs and product functionality, 
where intuitive design and personalized interactions converge to exceed expectations.</p>
</div>
</div>

</div>
</div>
</div>
</div>
</div>
</div>
</section>




<section className="pd-top-120">
<div className="container">
<div className="row">
<div className="col-12">

<div className="inflanar-section__head inflanar-section__center mg-btm-20">
<h2 className="inflanar-section__title aos-init aos-animate" data-aos="fade-in" data-aos-delay="400">Influencers in Different Categories</h2>
</div>
</div>
</div>
<div className="row">
<div className="col-lg-10 offset-lg-1 col-12 mg-top-30 aos-init aos-animate p-0" data-aos="fade-up" data-aos-delay="200">
<div className="inflanar-category">

<button  className={`inflanar-category__single ${styles.button_home}`} onClick={()=>navigate('/homeall')}>
<div className="inflanar-category__icon">
<img src="/img/in-cat-icon1.svg" alt="#" />
</div>
<div className="inflanar-category__content">
<h4 className="inflanar-category__title">Fashion</h4>
</div>
</button>


<button  className={`inflanar-category__single ${styles.button_home}`} onClick={()=>navigate('/homeall')}>
<div className="inflanar-category__icon">
<img src="/img/in-cat-icon2.svg" alt="#" />
</div>
<div className="inflanar-category__content">
<h4 className="inflanar-category__title">Health & Fitness</h4>
</div>
</button>


<button  className={`inflanar-category__single ${styles.button_home}`} onClick={()=>navigate('/homeall')}>
<div className="inflanar-category__icon">
<img src="/img/in-cat-icon3.svg" alt="#" />
</div>
<div className="inflanar-category__content">
<h4 className="inflanar-category__title" >Grooming</h4>
</div>
</button>


<button  className={`inflanar-category__single ${styles.button_home}`} onClick={()=>navigate('/homeall')}>
<div className="inflanar-category__icon">
<img src="/img/in-cat-icon4.svg" alt="#" />
</div>
<div className="inflanar-category__content">
<h4 className="inflanar-category__title" >Life Style</h4>

</div>
</button>


<button  className={`inflanar-category__single ${styles.button_home}`} onClick={()=>navigate('/homeall')}>
<div className="inflanar-category__icon">
<img src="/img/in-cat-icon5.svg" alt="#" />
</div>
<div className="inflanar-category__content">
<h4 className="inflanar-category__title">Spiritual</h4>
</div>
</button>


<button  className={`inflanar-category__single ${styles.button_home}`} onClick={()=>navigate('/homeall')}>
<div className="inflanar-category__icon">
<img src="/img/in-cat-icon6.svg" alt="#" />
</div>
<div className="inflanar-category__content">
<h4 className="inflanar-category__title">Education</h4>
</div>
</button>


<button  className={`inflanar-category__single ${styles.button_home}`} onClick={()=>navigate('/homeall')}>
<div className="inflanar-category__icon">
<img src="/img/in-cat-icon7.svg" alt="#" />
</div>
<div className="inflanar-category__content">
<h4 className="inflanar-category__title">Parenting Coach</h4>
</div>
</button>


<button  className={`inflanar-category__single ${styles.button_home}`} onClick={()=>navigate('/homeall')}>
<div className="inflanar-category__icon">
<img src="/img/in-cat-icon8.svg" alt="#" />
</div>
<div className="inflanar-category__content">
<h4 className="inflanar-category__title">Photog</h4>
</div>
</button>


<button  className={`inflanar-category__single ${styles.button_home}`} onClick={()=>navigate('/homeall')}>
<div className="inflanar-category__icon">
<img src="/img/in-cat-icon9.svg" alt="#" />
</div>
<div className="inflanar-category__content">
<h4 className="inflanar-category__title">Adventure</h4>
</div>
</button>


<button  className={`inflanar-category__single ${styles.button_home}`} onClick={()=>navigate('/homeall')}>
<div className="inflanar-category__icon">
<img src="/img/in-cat-icon10.svg" alt="#" />
</div>
<div className="inflanar-category__content">
<h4 className="inflanar-category__title">Travel & Tourism</h4>
</div>
</button>

</div>
</div>
</div>
</div>
</section>

<section className="pd-top-120 pd-btm-120">
<div className="container">
<div className="row">
<div className="col-12">

<div className="inflanar-section__head inflanar-section__center text-center mg-btm-20">

<h2 className="inflanar-section__title aos-init aos-animate" data-aos="fade-in" data-aos-delay="400">How Does It Work?</h2>
</div>
</div>
</div>
<div className="row">
<div className="col-lg-3 col-md-6 col-12 mg-top-30 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">

<div className="inflanar-hcard inflanar-hcard--one">
<div className="inflanar-hcard__img">
<img src="/img/in-howcard1.png" alt="#" />
</div>
<div className="inflanar-hcard__content">
<div className="inflanar-hcard__line"><img src="/img/in-line-shape1.svg" /></div>
<h4 className="inflanar-hcard__label">
<span>Step</span>
<b>1</b>
</h4>
<h4 className="inflanar-hcard__title">Search Influencer</h4>
<p className="inflanar-hcard__text">Seek those who inspire and resonate with you.</p>
</div>
</div>

</div>
<div className="col-lg-3 col-md-6 col-12 mg-top-30 aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">

<div className="inflanar-hcard inflanar-hcard--two">
<div className="inflanar-hcard__content inflanar-hcard__content__two">
<h4 className="inflanar-hcard__label">
<span>Step</span>
<b>2</b>
</h4>
<h4 className="inflanar-hcard__title">Book Session</h4>
<p className="inflanar-hcard__text">Select your influencer and request a session through the calendar.</p>
<div className="inflanar-hcard__line"><img src="/img/in-line-shape2.svg" /></div>
</div>
<div className="inflanar-hcard__img">
<img src="/img/in-howcard2.png" alt="#"/>
</div>
</div>

</div>
<div className="col-lg-3 col-md-6 col-12 mg-top-30 aos-init aos-animate" data-aos="fade-up" data-aos-delay="600">

<div className="inflanar-hcard inflanar-hcard--one">
<div className="inflanar-hcard__img">
<img src="/img/in-howcard3.png" alt="#" />
</div>
<div className="inflanar-hcard__content">
<div className="inflanar-hcard__line inflanar-hcard__line--v2"><img src="/img/in-line-shape3.svg" /></div>
<h4 className="inflanar-hcard__label">
<span>Step</span>
<b>3</b>
</h4>
<h4 className="inflanar-hcard__title">Make Payment</h4>
<p className="inflanar-hcard__text">Make the payment. The session with the influencer will reflect in upcoming sessions on your dashboard once the influencer accepts it.</p>
</div>
</div>

</div>
<div className="col-lg-3 col-md-6 col-12 mg-top-30 aos-init aos-animate" data-aos="fade-up" data-aos-delay="800">

<div className="inflanar-hcard inflanar-hcard--two">
<div className="inflanar-hcard__content inflanar-hcard__content__two">
<h4 className="inflanar-hcard__label">
<span>Step</span>
<b>4</b>
</h4>
<h4 className="inflanar-hcard__title">Attend Session</h4>
<p className="inflanar-hcard__text">Attend the session with the influencer.</p>
<div className="inflanar-hcard__line inflanar-hcard__line--v3"><img src="/img/in-line-shape2.svg" /></div>
</div>
<div className="inflanar-hcard__img">
<img src="/img/in-howcard4.png" alt="#" />
</div>
</div>

</div>
</div>
</div>
</section>
{showOverlayProfile && (
  <div className='overlay'>
  <BookInfluencer influencerdata={selectedInfluencer} onClose={handleCloseOverlay}/></div>
)}
</div>
    )}
    </>
  )
}
export default Home;