import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import AlertMessage from '../../../components/shared/alertMessage/AlertMessage';
import { mailbody, mailbodyAcceptance, mailbodyForInf } from '../../../utils/common';
import styles from './BookInfluencer.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { confirmMail } from '../../../services/auth-service/requestService';
import moment from 'moment';
import { UserContext } from '../../../App';
import { GetClient_Info } from '../../../services/auth-service/clientAccountService';
import { getInfluencerDetails } from '../../../services/auth-service/InflAccountService';

const CheckAvailabilty = () => {
    const { id } = useParams();
    const { state } = useContext(UserContext);
    const navigate = useNavigate();

    const [alertData, setAlertData] = useState(null);
    const [timeSlots, setTimeSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [slotsPerPage] = useState(10);
    const [purpose, setPurpose] = useState('');
    const [loading, setLoading] = useState(false);
    const [sessionType, setSessionType] = useState('Single');
    const [isActiveButton1, setIsActiveButton1] = useState(false);
    const [isActiveButton2, setIsActiveButton2] = useState(true);

    const [clientData, setClientData] = useState(null);
    const [influencerData, setInfluencerData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const influencerResponse = await getInfluencerDetails(id);
                if(influencerResponse.verifyFacebook||influencerResponse.verifyInstagram||influencerResponse.verifyTwitter||influencerResponse.verifyYoutube){
                  setInfluencerData(influencerResponse);
                }else{
                  navigate('/aasim');
                }               
                if (state) {
                    // Fetch client and influencer data if state exists
                    const clientResponse = await GetClient_Info();                 
                    setClientData(clientResponse.data);                  
                }          
            } catch (error) {
                console.error('Error fetching client or influencer data:', error);
            }
        };
        fetchData();
    }, [state, id]);

    useEffect(() => {
        const fetchTimeSlots = async () => {
            try {
                const influencerId = state && influencerData ? influencerData._id : id;
                const fetchType = sessionType;
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/api/influencertimeslot?influencerId=${influencerId}&type=${fetchType}`
                );
                const sortedSlots = response.data.sort((a, b) => {
                    const dateComparison = new Date(a.date) - new Date(b.date);
                    if (dateComparison === 0) {
                        return a.startTime < b.startTime ? -1 : a.startTime > b.startTime ? 1 : 0;
                    }
                    return dateComparison;
                });
                const threeDaysFromNow = new Date(Date.now() + 72 * 60 * 60 * 1000);
                const filteredSlots = sortedSlots.filter((slot) => {
                    const dateOnly = slot.date.split('T')[0];
                    const currentNewTime = `${dateOnly}T${slot.startTime}:00`;
                    return new Date(currentNewTime) > threeDaysFromNow;
                });
                setTimeSlots(filteredSlots);
            } catch (error) {
                console.error('Error fetching time slots:', error);
            }
        };
        fetchTimeSlots();
    }, [state, id, influencerData,sessionType]);


    const toggleClassButton1 = () => {
      setSessionType('Group')
      setIsActiveButton1((prevIsActive) => !prevIsActive);
      setIsActiveButton2(false); // Ensure the other button is inactive
      // Reset states when toggling to Single
    resetStates();
    };
  
    const toggleClassButton2 = () => {
      setSessionType('Single')
      setIsActiveButton2((prevIsActive) => !prevIsActive);
      setIsActiveButton1(false); // Ensure the other button is inactive
      // Reset states when toggling to Single
      resetStates();
    };
    // Function to reset relevant states
    const resetStates = () => {
      setSelectedSlot(null); // Reset selected slot
      setPurpose(''); // Reset purpose
      setCurrentPage(1); // Reset pagination
    };

    const handleSlotSelect = (slot) => {
        if (!state) {
            navigate('/signin');
        } else {
           if(!clientData){
            setAlertData({
              message: `Sorry, you can't book session with an influencer sign in. Please sign in as a user.`,
            });
           }
            setSelectedSlot(slot);
        }
    };

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const handleInputChange = (e) => setPurpose(e.target.value);

    const handleProceedToPayment = async () => {
        try {
            const { data: { key } } = await axios.get(`${process.env.REACT_APP_API_URL}/api/getkey`);
            const { data: { order } } = await axios.post(`${process.env.REACT_APP_API_URL}/api/checkout`, {
                amount: totalAmount,
            });

            const options = {
                key,
                amount: order.amount,
                currency: "INR",
                name: "Inkorero",
                description: "Transaction for Influencer",
                image: "img/Social-56.png",
                order_id: order.id,
                handler: async function (response) {
                    try {
                        const mailAcceptance = mailbodyAcceptance({ razorpay_order_id: response.razorpay_order_id });
                        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/paymentverification`, {
                            InfluencerId: influencerData._id,
                            clientId: clientData._id,
                            selectedSlot,
                            slotid: selectedSlot._id,
                            amount: totalAmount,
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_signature: response.razorpay_signature,
                            mailAcceptance,
                            purpose,
                            platformfee: influencerData.platformfee
                        });
                        setLoading(true);
                        if (res.status === 201) {
                            const email = clientData.email;
                            const receivedData = mailbody(res.data.request, amountfee, gstRate, influencerData.platformfee, RazorpayCharge);
                            const receivedDataInf = mailbodyForInf(res.data.request, amountfee, gstRate, influencerData.platformfee, RazorpayCharge);
                            await confirmMail({ ...receivedData, email });
                            await confirmMail({ ...receivedDataInf, email: influencerData.email });
                            setLoading(false);
                            navigate('/makepayment');
                        } else {
                            throw new Error('Client data does not meet specific condition');
                        }
                    } catch (error) {
                        console.error('Error in handler axios.post:', error);
                    }
                },
                prefill: {
                    name: `${clientData.firstName} ${clientData.lastName}`,
                    email: `${clientData.email}`,
                    contact: `${clientData.phone}`
                },
                notes: {
                    address: "Razorpay Corporate Office"
                },
                theme: {
                    color: "#FE2C55"
                }
            };

            const razor = new window.Razorpay(options);
            razor.open();
        } catch (error) {
            console.error('Error creating request:', error);
        }
    };

    if (loading) {
        return (
            <div className="spinner-container">
                <FontAwesomeIcon className="spinner" icon={faSpinner} />
            </div>
        );
    }
    let amountfee;
    if(sessionType==='Single'){
      amountfee = influencerData?.amountforhalf || 0;
    }else{
      amountfee = influencerData?.amountforfull || 0;
    }   

    const gstRate = (influencerData?.platformfee || 0) * 0.18;
    const totalAmount = amountfee + (influencerData?.platformfee || 0) + gstRate;
    const RazorpayCharge = Math.round((totalAmount * 2.36) / 100 * 100) / 100;

    return (
        <>
            <div className="container mt-5">
            <div className="row align-items-center">
                <div className="col-md-6">
                  <h2>Book Influencer</h2>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <div className="list-group inflanar-signin__options" id="list-tab" role="tablist">
                    <button 
                      className={`list-group-item rounded-pill ${isActiveButton2 ? 'active' : 'inactive'}`} 
                      style={{ borderTopWidth: '1px', padding: '1rem 1rem', fontSize: '14px', height: 'auto' }}
                      data-bs-toggle="list" 
                      name="client" 
                      value="client" 
                      role="tab" 
                      onClick={toggleClassButton2}
                    >
                      <span>Single Session (1:1)</span>
                    </button>
                    <button 
                      className={`list-group-item rounded-pill ${isActiveButton1 ? 'active' : 'inactive'}`} 
                      style={{ borderTopWidth: '1px', padding: '1rem 1rem', fontSize: '14px', height: 'auto' }}
                      data-bs-toggle="list" 
                      name="Influencer" 
                      value="Influencer" 
                      role="tab" 
                      onClick={toggleClassButton1}
                    >
                      <span>Group Session</span>
                    </button>
                  </div>
                </div>
              </div>
              {influencerData ? (
                <div className='pl-3' style={{ display: 'flex', alignItems: 'center' }}>
                  <h6 style={{ margin: 0, marginRight: '8px' }}>Influencer Name:</h6>
                  <span className='font-weight-bold'>{influencerData.firstName} {influencerData.lastName}</span>
                </div>
              ) : (
                <p>Loading...</p>
              )}
                {timeSlots.length > 0 ? (
                    <>
                        <p className="mb-3 pl-3">Select a time slot to proceed with the booking: {sessionType}</p>
                        <div className="row">
                            {timeSlots.slice((currentPage - 1) * slotsPerPage, currentPage * slotsPerPage).map((slot) => (
                                <div key={slot._id} className="col-md-3 mb-3">
                                    <div
                                        key={slot._id}
                                        className={`card ${styles.cardslot} ${selectedSlot === slot ? 'bg-primary text-white' : ''}`}
                                        onClick={() => handleSlotSelect(slot)}
                                    >
                                        <div className="card-body">
                                            <h5 className="card-title">{slot.startTime} - {slot.endTime}</h5>
                                            <h6 className="card-text">{moment(slot.date).format('DD/MM/YYYY')}</h6>
                                            {state?.type === 'Group' && (
                                                <span style={{ fontSize: '70%' }}>Remaining {slot.participants}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {selectedSlot && (
                            <div style={{ padding: "16px" }}>
                                <textarea
                                    className={`mb-1  ${styles.textpurpose}`}
                                    id={styles.inp}
                                    placeholder="Write name, any one of your social media identities and purpose of session (15-75 words)"
                                    minLength={90}
                                    maxLength={400}
                                    onChange={handleInputChange}
                                />
                            </div>
                        )}
                        {purpose && purpose.length > 50 && (
                            <>
                                <div className={`container rounded m-3 ${styles.paymentContainer}`}>
                                    <h5 className='m-0 p-2 rounded bg-light'>Order Summary</h5>
                                    <table className="table m-0">
                                        <tbody className={`${styles.cardslot}`}>
                                            <tr >
                                                <td >
                                                    <p>
                                                        <strong>Influencer Fee </strong>
                                                        <span style={{ fontSize: "80%" }}>(including GST and Taxes)</span>
                                                    </p>
                                                </td>
                                                <td>₹{amountfee}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <strong>Platform Fee </strong>
                                                        <span style={{ fontSize: "80%" }}>(including 2.36% payment gateway charges)</span>
                                                    </p>
                                                </td>
                                                <td>₹{influencerData?.platformfee || 0}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <strong>GST on Platform Fee</strong>
                                                        <span style={{ fontSize: "80%" }}>({9}% CGST and {9}% SGST)</span>
                                                    </p>
                                                </td>
                                                <td>₹{gstRate}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <strong>Total Amount Payable </strong>
                                                        <span style={{ fontSize: "80%" }}>(inclusive of all taxes)</span>
                                                    </p>
                                                </td>
                                                <td>₹{totalAmount}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <span className="pl-3" style={{ fontSize: "80%", color: "grey" }}>Payment gateway charges 2.36% amounts to ₹{RazorpayCharge} </span> <br />
                                <span className="pl-3" style={{ fontSize: "80%", color: "grey" }}>Platform Fee and GST on Platform Fee are non-refundable</span>
                                <div className="pl-3">
                                    <button
                                        className="btn btn-primary mt-3"
                                        onClick={handleProceedToPayment}
                                        disabled={!selectedSlot}
                                    >
                                        Proceed to Payment
                                    </button>
                                </div>
                            </>
                        )}
                        <nav className="mt-3">
                            <ul className="pagination">
                                {Array.from({ length: Math.ceil(timeSlots.length / slotsPerPage) }, (_, i) => i + 1).map((pageNumber) => (
                                    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                                        <button className="page-link ml-2" onClick={() => handlePageChange(pageNumber)}>
                                            {pageNumber}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            </nav>
                            </>
                        ) : (
                            <p>Currently, no slots are available for booking.</p>
                        )}
                        {alertData && (
                          <div className="overlay">
                            <AlertMessage message={alertData.message} type={alertData.type} onClose={() => setAlertData(null)} />
                            </div>
                        )}
                    </div>
                    </>
                );
            };
            
  export default CheckAvailabilty;