import React,{useState,useEffect,useCallback} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { fetchInfluencers, searchInfluencers } from '../../../services/auth-service/InflAccountService';
import AlertMessage from '../../../components/shared/alertMessage/AlertMessage';
import BookInfluencer from '../../clientAbout/bookInfluencer/BookInfluencer';
import { UpdateRequestAfterTwo } from '../../../services/auth-service/requestService';
import { debounce } from '../../../services/common/debounce';
import HomeCard from '../homecard/HomeCard';
import styles from './HomeInfluencer.module.css'


const HomeInfluencer = ({initialValues}) => 
{
const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(1);
const [alertData, setAlertData] = React.useState(null);
const [influencers,setInfluencers]=useState([])
const [searchQuery, setSearchQuery] = useState('');
const [showOverlayProfile, setShowOverlayProfile] = useState(false);
const [selectedInfluencer, setSelectedInfluencer] = useState(null);
const [loading, setLoading] = useState(true);


const handleCardClick = (influencerData) => {
    setSelectedInfluencer(influencerData);
    setShowOverlayProfile(true);
  };
  const handleCloseOverlay = () => {
    setSelectedInfluencer(null);
    setShowOverlayProfile(false);
  };


const fetchAllInfluencers = async(page)=>{
        try {
          const response = await Promise.all([UpdateRequestAfterTwo()]);
          //const response = await fetchInfluencers(page);
          if(!response.status === 200){
            setAlertData({
              message: 'Failed to get Response',
            });     
          }else if(response===204){
           setAlertData({
            message: 'No Requests for now',
          });
          }
          //setInfluencers(response[1].influencers);
          //setTotalPages(response[1].totalPages);
        } catch (error) {
          console.log(error);
   }
}

useEffect(() => {
    // Fetch data when the component mounts or when currentPage changes
    fetchAllInfluencers(currentPage);
  }, [currentPage]);


  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1)); // Ensure not to go below page 1
  };
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const renderPageButtons = () => {
    const buttons = [];
  
    const renderEllipsis = (id) => (
      <span key={`ellipsis-${id}`} className='ml-2 mt-2'>...</span>
    );
  
    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 1) {
        // Display first, last, and nearby pages
        buttons.push(
          <button
            className={`btn btn-outline-primary mt-2 ml-2 ${i === currentPage ? 'active' : ''}`}
            key={i}
            onClick={() => handlePageClick(i)}
            disabled={i === currentPage}
          >
            {i}
          </button>
        );
      } else if (buttons[buttons.length - 1] !== renderEllipsis()) {
        // Display ellipsis if not already displayed
        buttons.push(renderEllipsis(i));
      }
    }
  
    return buttons;
  };

  const handleSearch = async (query, page) => {
    try {
    const pageSize =12;
    const response = await searchInfluencers(query,page,pageSize);
    setInfluencers(response.influencers);
    setTotalPages(response.totalPages);
    } catch (error) {
      console.error('Error fetching influencers:', error);
    }finally {
          setLoading(false);
        }
  };
  const debouncedSearch = useCallback(
    debounce((query, page) => {
      handleSearch(query, page);
    }, 300),
    []
  );

  // UseEffect to trigger the debouncedSearch function when the searchQuery changes
  useEffect(() => {
    debouncedSearch(searchQuery, currentPage);
  }, [searchQuery, currentPage, debouncedSearch]);

 

  return (
    <>
    {loading ? (
        // Display spinner while waiting for the response
        <div className="spinner-container">
        <FontAwesomeIcon
                        className="spinner"
                        icon={faSpinner}
                      />
                      </div>
      ) : (
      <div>
<div className="row">
<div className="col-lg-12 col-md-12 col-12 mg-top-30 p-0">

<div className="inflanar-pdbox">
  <div className={`inflanar-profile-info ${styles.inflanar_Home_resp}`}>
    {/* Heading */}
    <div className="inflanar-profile-info__head">
      <h3 className='text-danger font-italic'>
        Book a Session
      </h3>
    </div> 

    {/* Search */}
    <div
      className="inflanar-search-form inflanar-search-form__hero aos-init aos-animate"
      data-aos="fade-up"
      data-aos-delay="400"
    >
      <form className="inflanar-search-form__form" action="#">
        <div className="inflanar-search-form__group">
          <div className="inflanar-search-form__icon">
            <FontAwesomeIcon
              style={{ fontSize: "18px" }}
              data-toggle="collapse"
              icon={faSearch}
            />
          </div>
          <input
            type="text"
            placeholder="Search Influencer"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </form>
    </div>
  </div>
</div>


</div>


</div>
<div className="inflanar-profile-info mg-top-30 inflanar-profile-info__list-container">
  {/* Header Row */}
  {/* Dynamic Content Rows */}
  <div className={`${styles.card_grid}`}>
    {influencers.map((influencer, index) => (
      <div
        className={`${styles.card_item} cursor-pointer`}
        key={index}
        onClick={() => handleCardClick(influencer)}
      >
        <HomeCard
          firstName={influencer.firstName}
          lastName={influencer.lastName}
          category={influencer.category}
          insta={influencer.Instagram}
          verifyInstagram={influencer.verifyInstagram}
          fb={influencer.Facebook}
          verifyFacebook={influencer.verifyFacebook}
          linkdin={influencer.Youtube}
          verifyYoutube={influencer.verifyYoutube}
          twitter={influencer.Twitter}
          verifyTwitter={influencer.verifyTwitter}
        />
      </div>
    ))}
  </div>

  <div className="text-right">
    <button
      className="btn btn-outline-primary mt-2"
      onClick={handlePrevPage}
      disabled={currentPage === 1}
    >
      Previous Page
    </button>
    {renderPageButtons()}
    <button
      className="btn btn-outline-primary mt-2 ml-2"
      onClick={handleNextPage}
      disabled={currentPage === totalPages}
    >
      Next Page
    </button>
  </div>
</div>
{showOverlayProfile && (
  <div className='overlay'>
  <BookInfluencer clientdata={initialValues} influencerdata={selectedInfluencer} onClose={handleCloseOverlay}/></div>
)}
{alertData && (
  <div className="overlay">
    {/* You can add an overlay to dim the background if needed */}
    <AlertMessage
      message={alertData.message}
      type={alertData.type}
      onClose={() => setAlertData(null)}
    />
  </div>
)}
</div>
    )}
</>
  )
}

export default HomeInfluencer