import React from 'react'
import styles from './HomeCard.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
    faUser,
  } from "@fortawesome/free-solid-svg-icons";
  import {
    faFacebook,
    faInstagram,
    faXTwitter,
    faLinkedinIn
  } from "@fortawesome/free-brands-svg-icons";

const HomeCard = ({ firstName,lastName, category, insta,verifyInstagram,fb,verifyFacebook,twitter,verifyTwitter, linkdin,verifyYoutube,onClick }) => {
   
  return (
    <div
  className={styles.card}
  onClick={onClick}
  role="button"
  tabIndex={0}
  onKeyDown={(e) => {
    if (e.key === 'Enter') onClick();
  }}
>
  <div className={styles.nameContainer}>
    <h5 className={styles.name}>
      {firstName} {lastName}
    </h5>
  </div>
  <div className={styles.nameContainer}>
    <p className={styles.category}>{category}</p>
  </div>
  <div className={styles.nameContainer}>
    <div className={styles.sessionFees}>
      {insta && (
        <p className="mb-2">
          <FontAwesomeIcon
            className={`${styles.instaIcon} ${styles.icons} rounded-circle`}
            icon={faInstagram}
          />
          <span className="text-dark font-weight-bold"> - </span>
          <a
            href={`https://www.instagram.com/${insta.startsWith('@') ? insta.slice(1) : insta}`}
            target="_blank"
            rel="noopener noreferrer"
            className={`${styles.card_font}`}
            onClick={(e) => e.stopPropagation()} // Prevent card click
          >
            {insta.startsWith('@') ? insta.slice(1) : insta}
          </a>{verifyInstagram &&(<span className={styles.verifiedTick}><FontAwesomeIcon
            className=""
            style={{marginRight:"-6px", marginLeft:"5px"}}
            icon={faCircleCheck}
          /></span>)}
        </p>
      )}
      {fb && (
        <p className="mb-2">
          <FontAwesomeIcon
            className={`${styles.fbIcon} ${styles.icons} rounded-circle`}
            icon={faFacebook}
          />
          <span className="text-dark font-weight-bold"> - </span>
          <a
            href={`https://www.facebook.com/${fb.startsWith('@') ? fb.slice(1) : fb}`}
            target="_blank"
            rel="noopener noreferrer"
            className={`${styles.card_font}`}
            onClick={(e) => e.stopPropagation()} // Prevent card click
          >
            {fb.startsWith('@') ? fb.slice(1) : fb}
          </a>{verifyFacebook&&(<span className={styles.verifiedTick}><FontAwesomeIcon
            className=""
            style={{marginRight:"-6px", marginLeft:"5px"}}
                                    icon={faCircleCheck}
          /> </span>)}
        </p>
      )}
      {linkdin && (
        <p className="mb-2">
          <FontAwesomeIcon
            className={`${styles.faLinkedinIcon} ${styles.icons}`}
            icon={faLinkedinIn}
          />
          <span className="text-dark font-weight-bold"> - </span>
          <a
            href={`https://www.linkedin.com/in/${linkdin.startsWith('@') ? linkdin.slice(1) : linkdin}`}
            target="_blank"
            rel="noopener noreferrer"
            className={`${styles.card_font}`}
            onClick={(e) => e.stopPropagation()} // Prevent card click
          >
            {linkdin.startsWith('@') ? linkdin.slice(1) : linkdin}
          </a>{verifyYoutube &&(<span className={styles.verifiedTick}><FontAwesomeIcon
            className=""
            style={{marginRight:"-6px", marginLeft:"5px"}}
                                    icon={faCircleCheck}
          /> </span>)}
        </p>
      )}
      {twitter && (
        <p className="mb-2">
          <FontAwesomeIcon
            className={`${styles.twIcon} ${styles.icons} rounded-circle`}
            icon={faXTwitter}
          />
          <span className="text-dark font-weight-bold"> - </span>
          <a
            href={`https://x.com/${twitter.startsWith('@') ? twitter.slice(1) : twitter}`}
            target="_blank"
            rel="noopener noreferrer"
            className={`${styles.card_font}`}
            onClick={(e) => e.stopPropagation()} // Prevent card click
          >
            {twitter.startsWith('@') ? twitter.slice(1) : twitter}
          </a>{verifyTwitter &&(<span className={styles.verifiedTick}><FontAwesomeIcon
            className=""
            style={{marginRight:"-6px", marginLeft:"5px"}}
                                    icon={faCircleCheck}
          /> </span>)}
        </p>
      )}
    </div>
  </div>
</div>

  )
}

export default HomeCard