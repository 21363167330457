import React,{useState} from 'react';
import styles from './influenceredit.module.css'
import { updateInfluencer } from '../../../services/auth-service/InflAccountService';
import { useNavigate } from "react-router-dom";
import AlertMessage from '../../../components/shared/alertMessage/AlertMessage';
import { categories,followers } from '../../../services/common/constants';


const Editprofile = ({ initialValues,onClose }) => {
  const [alertData, setAlertData] = React.useState(null);
    const navigate = useNavigate();
    // const categories = ["Lifestyle"," Health & Fitness", "Food & Nutrition","Fashion","Author/Literature","Spiritual & Motivational",
    //   "Education","Career Coach", "Technology" , "Live spaces – Interiors", "Travel & Tourism", "Sports","Professional"
    //    , "Adventure", "Astrology", "Photographer", "Others"];  
     //const followers =["0-50k","51k-100K","100K-500K","501K-1M","1M-5M","Above 5M"]
     const [values, setValues] = useState(initialValues);
     const handleChange = (key, newValue) => {
        setValues((prevValues) => ({
          ...prevValues,
          [key]: newValue,
        }));
      };
      const handleRadioChange = (newValue) => {
        setValues((prevValues) => ({
          ...prevValues,
          gender: newValue,
        }));
      };
      const handleDropdownChange = (newValue) => {
        setValues((prevValues) => ({
          ...prevValues,
          category: newValue,
        }));
      };
      const handleFollowersChange = (newValue,name)=>{
        setValues((prevalues)=>({
          ...prevalues,
          [name]:newValue
        }))
      }
      const handleSubmit = async(e) => {
        try {
        e.preventDefault();
        const res =await updateInfluencer(values);
        if(!res.status === 200){
            setAlertData({
              message: 'Failed to get Response',
            });
          }else if(res===401){
            setAlertData({
              message: 'User not found or no chnages made',
            });
          }
          else{
            setAlertData({
              message: 'Updated!!',
            });
            navigate('/influencerhome')
          }
        // You can perform additional actions here, such as saving the updated values to a database.
       } catch (error) {
        console.log(error);
       }
      };

  return (
    <>
    <div className={`inflanar-supports inflanar-profile-info mg-top-80 ${styles.overlaycontent} ${styles.card}`} style={{padding:'0px'}}>
    <div className={`inflanar-signin__inner card`} style={{padding:'10px'}}>
    <span className={`btn btn-outline-dark close-btn ${styles.closebutton} mb-4` } onClick={onClose}>&times;</span>
    <form onSubmit={handleSubmit}>
    <div className="row">
    <div className="col-lg-6 col-md-6 col-6">
    <div className="form-group inflanar-form-input ">
    <label>First Name*</label>
    <input className="ecom-wc__form-input" type="text" name='firstName' 
    required="required" autoComplete="off"
    placeholder="firstName" id={`firstName`}
    value={values.firstName}
    onChange={(e) => handleChange( 'firstName', e.target.value)}
    />
    </div>
    </div>
    <div className="col-lg-6 col-md-6 col-6">
    <div className="form-group inflanar-form-input ">
    <label>Last Name*</label>
    <input className="ecom-wc__form-input" type="text" name='lastName'  
    autoComplete="off"
    required="required" 
    placeholder="lastName"
    id={`lastName`}
    value={values.lastName}
    onChange={(e) => handleChange('lastName', e.target.value)}
    />
    </div>
    </div>
    <div className="col-lg-4 col-md-4 col-4">
    <div className={`form-group `}>
    <label className={`${styles.radio_label} mb-0`}>
      <input
        type="radio"
        value="Male"
        name="gender"
        id="radio-Male"
              checked={values.gender === 'Male'}
              onChange={() => handleRadioChange('Male')}
      />
      <span className={styles.radio_text}>
      Male
      </span>
    </label>
    </div>
    </div>
    <div className="col-lg-4 col-md-4 col-4 p-0">
    <div className={`form-group `}>
    <label className={`${styles.radio_label} mb-0`}>
      <input
        type="radio"
        value="Female"
        name="gender"
        id="radio-Female"
              checked={values.gender === 'Female'}
              onChange={() => handleRadioChange('Female')}
      />
      <span className={styles.radio_text}>
      Female
      </span>
    </label>
    </div>
    </div>
    <div className="col-lg-4 col-md-4 col-4">
    <div className={`form-group `}>
    <label className={`${styles.radio_label} mb-0`}>
      <input
        type="radio"
        value="No Gender"
        name="gender"
        id="radio-NoGender"
              checked={values.gender === 'No Gender'}
              onChange={() => handleRadioChange('No Gender')}
      />
      <span className={styles.radio_text}>
      Skip
      </span>
    </label>
    </div>
    </div>
    <div className="col-lg-6 col-md-6 col-6">
    <div className="form-group inflanar-form-input ">
    <label>Email*</label>
    <input className="ecom-wc__form-input" type="email"
    autoComplete="off"
    name='email'    
    required="required"                      
    placeholder="email address"
    id={`lastName`}
    value={values.email}
    disabled
    onChange={(e) => handleChange('email', e.target.value)}
    />
    </div>
    </div>
    <div className="col-lg-6 col-md-6 col-6">
    <div className="form-group inflanar-form-input ">
    <label>Phone*</label>
    <input className="ecom-wc__form-input" type="Number"
    name='phone'   required="required"   
    // value={userRegistration.phone}     
    // onChange={handleinput}         
    autoComplete="off"
    placeholder="Phone"
    id={`phone`}
    value={values.phone}
    disabled
    onChange={(e) => handleChange('phone', e.target.value)}
    />
    </div>
    </div>
    <div className='col-lg-3 col-md-3 col-6'>
                <div className={`form-group inflanar-form-input `} >     
                    <input
                      className={styles.input_social}
                      type="text"
                      name='Instagram'
                      autoComplete="off"
                      placeholder='Instagram'
                      id={`Instagram`}
                     value={values.Instagram}
                     onChange={(e) => handleChange('Instagram', e.target.value)}
                     readOnly={values.verifyInstagram}
                    />  
                  </div>
                  <div className="form-group inflanar-form-input ">
                      <select id="dropdown"
                      value={values.InstagramFollower}
                      name='InstagramFollower'
                      onChange={(e) => handleFollowersChange(e.target.value,e.target.name)}
                      disabled={!values.Instagram}
                      >
                        <option value="" disabled>
                          Select Followers
                        </option>
                        {followers.map((option) => (
                          <option key={option} name='Instagram' value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                </div>
                 
                  </div>
                  <div className='col-lg-3 col-md-3 col-6'>
                <div className={`form-group inflanar-form-input `} >  
                    <input
                      className={styles.input_social}
                      type="text"
                      name='Facebook'  
                      autoComplete="off"
                      placeholder="Facebook"
                      id={`Facebook`}
                      value={values.Facebook}
                      onChange={(e) => handleChange('Facebook', e.target.value)}
                      readOnly={values.verifyFacebook}
                    />
                  </div>
                  <div className="form-group inflanar-form-input ">
                      <select id="dropdown"
                      value={values.FacebookFollower}
                      name='FacebookFollower'
                      onChange={(e) => handleFollowersChange(e.target.value,e.target.name)}
                      disabled={!values.Facebook}
                      >
                        <option value="" disabled>
                          Select Followers
                        </option>
                        {followers.map((option) => (
                          <option key={option} name='FacebookFollower' value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                </div>
                  </div>            
                  <div className='col-lg-3 col-md-3 col-6'>
                <div className={`form-group inflanar-form-input `} >  
                    <input
                      className={styles.input_social}
                      type="text"
                      name='Twitter'  
                      autoComplete="off"
                      placeholder="X(Twitter)"
                      id={`Twitter`}
                      value={values.Twitter}
                      onChange={(e) => handleChange('Twitter', e.target.value)}
                      readOnly={values.verifyTwitter}
                    />
                  </div>
                 <div className="form-group inflanar-form-input ">
                  <select id="dropdown"
                  value={values.TwitterFollower}
                  name='TwitterFollower'
                  onChange={(e) => handleFollowersChange(e.target.value,e.target.name)}
                  disabled={!values.Twitter}
                  >
                    <option value="" disabled>
                      Select Followers
                    </option>
                    {followers.map((option) => (
                      <option key={option} name='TwitterFollower' value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
            </div>
                  </div>
                  <div className='col-lg-3 col-md-3 col-6'>
                  <div className={`form-group inflanar-form-input `} >  
                    <input
                      className={styles.input_social}
                      type="text"
                      name='Youtube' 
                      autoComplete="off"
                      placeholder="LinkedIn"
                      id={`Youtube`}
                      value={values.Youtube}
                      onChange={(e) => handleChange('Youtube', e.target.value)}
                      readOnly={values.verifyYoutube}
                    />
                  </div>
                  <div className="form-group inflanar-form-input ">
                  <select id="dropdown"
                  value={values.YoutubeFollower}
                  name='YoutubeFollower'
                  onChange={(e) => handleFollowersChange(e.target.value,e.target.name)}
                  disabled={!values.Youtube}
                  >
                    <option value="" disabled>
                      Select Followers
                    </option>
                    {followers.map((option) => (
                      <option key={option} name='YoutubeFollower' value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
            </div>
                  </div>     
                  <div className='col-lg-12 col-md-12 col-12'>        
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                    <div className="form-group inflanar-form-input">
                      <label>Fee: Group*</label>
                      <input
                        className="ecom-wc__form-input"
                        type="text"
                        name="amountforfull"
                        required="required"
                        autoComplete="off"
                        placeholder="Amount for one hour"
                        id={`amountforfull`}
                        value={values.amountforfull}
                        onChange={(e) =>
                          handleChange("amountforfull", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-6">
                    <div className="form-group inflanar-form-input">
                      <label>Fee: Single (1:1)*</label>
                      <input
                        className="ecom-wc__form-input"
                        type="text"
                        name="amountforhalf"
                        required="required"
                        autoComplete="off"
                        placeholder="Amount for half hour"
                        id={`amountforhalf`}
                        value={values.amountforhalf}
                        onChange={(e) =>
                          handleChange("amountforhalf", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className='col-lg-12 col-md-12 col-12'>
                  <div className="form-group inflanar-form-input ">
                  <label>Category</label>
                      <select id="dropdown"
                      value={values.category}
                      onChange={(e) => handleDropdownChange(e.target.value)}
                      
                      >
                        <option value="" disabled>
                          Select a category
                        </option>
                        {categories.map((option) => (
                          <option key={option} name='category' value={option} required="required">
                            {option}
                          </option>
                        ))}
                      </select>
                </div>
                </div>
    <div className="col-lg-12 col-md-12 col-12">
    <div className="form-group inflanar-form-input ">
    <label>Introduction*</label>
    <textarea className="ecom-wc__form-input" type="email"
    autoComplete="off"
    name='Intro'
    required="required"
    style={{height:'64px'}}
    // value={userRegistration.email}     
    // onChange={handleinput}                         
    placeholder="Type here ...."
    id={`Intro`}
    value={values.Intro}
    onChange={(e) => handleChange('Intro', e.target.value)}
    />
    </div>
    </div>
    <div className="col-12">
    <div className="form-group mg-top-40">
    <button type="submit" className="inflanar-btn" ><span>Update Account</span></button>
    </div>
    </div>
  
    </div>
    </form>
    </div>
    </div>
    {alertData && (
      <div className="overlay">
        {/* You can add an overlay to dim the background if needed */}
        <AlertMessage
          message={alertData.message}
          type={alertData.type}
          onClose={() => setAlertData(null)}
        />
      </div>
    )}
    </>
  )
}

export default Editprofile